
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 184,
            //             phoneElementId: 188,
            //             descList: [
            //                 {
            //                     descName: '3D Real-time Map',
            //                     sort: 1
            //                 }, {
            //                     descName: '3D Real-time Map',
            //                     sort: 2
            //                 }, {
            //                     descName: 'Sevnce Robotics provides customers with high-precision 3D visual positioning solutions, using laser 3D SLAM technology for real-time map modeling, mapping and presenting objects, scenes, and data within the inspection plant area. It can build high-precision 3D point cloud maps that are not easily affected by the environment, and achieve clearer, more intuitive, and all-round inspection monitoring.',
            //                     sort: 3
            //                 }, 
            //             ],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 185,
            //             phoneElementId: 189,
            //             descList: [ {
            //                     descName: 'More Intuitive Visual Effects',
            //                     sort: 1
            //                 },{
            //                     descName: 'By utilizing 3D visualization technology to provide more intuitive and realistic visual effects, users can observe objects or scenes from different angles, obtain more comprehensive information, and help them understand and recognize more accurately.',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 186,
            //             phoneElementId: 190,
            //             descList: [
            //                 {
            //                     descName: 'Visual Monitoring of the Data',
            //                     sort: 1
            //                 }, {
            //                     descName: 'By connecting the visual data of the operating equipment in the factory area with the cloud system, monitoring and management of various data information can be achieved. Based on the data model, comprehensive data analysis can be provided to managers, improving the timeliness and effectiveness of information query, processing, and interaction.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId:187,
            //             phoneElementId:191,
            //             descList: [
            //                 {
            //                     descName: 'Improving User Interaction Experience',
            //                     sort: 1
            //                 }, {
            //                     descName: 'By optimizing the user interaction experience through 3D real-time scenes, users can obtain a more vivid and intuitive display effect. The three-dimensional presentation of inspection routes and inspection points clearly and effectively reflects the inspection effect and functions, improving user awareness and satisfaction with the product.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //     ],
            elmentList: []
        }
    },
    created() {
        //           this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}
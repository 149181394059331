<template>
  <!-- 防爆云台，英文版 -->
  <div class="around threeDMap" v-if="elmentList.length">
    <div class="threeDMap-content">
      <div class="content-one" v-for="index in 4" :key="index"  :id="'elm' + elmentList[index - 1].id">
        <img :src="elmentList[index - 1].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[index - 1].phoneImg" v-else />
        <div class="img-con" v-if="index === 1 && $store.state.pageset.pcshow">
          <div class="title">
            <h1>{{ elmentList[index - 1].descList[0].descName }}</h1>
          </div>
        </div>
        <div :class="index === 1 ? 'dec-two dec' : 'dec'" v-animate="'queue-bottom'">
          <div class="dec-left">
            <p class="p1">
              {{
                index === 1
                  ? elmentList[index - 1].descList[1].descName
                  : elmentList[index - 1].descList[0].descName
              }}
            </p>
            <p class="p2">
              {{
                index === 1
                  ? elmentList[index - 1].descList[2].descName
                  : elmentList[index - 1].descList[1].descName
              }}
            </p>
            <!-- <div class="scroll-wrap" v-if="index === 1">
              <div class="remark">
                本页面视频中出现的产品仅供参考，产品的外观、配置、型号等细节可能有所差异，具体请以当地实际交付为准。
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import threeDMap_en from "./threeDMap_en.js";

export default threeDMap_en;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../../common.scss";
@import "../threeDMap.scss";

@media screen and (max-width: 750px) {
    .threeDMap {
        .content-one {
            .dec {
              height: 240px !important;
              bottom: 240px;
               .dec-left{
                .p1{
                  font-size: 14px !important;
                }
               }
            }
        }
    }
}
</style>
